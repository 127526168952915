import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from './GODS 3.0 white.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faLocationDot, faPaperPlane, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faSquareFacebook } from '@fortawesome/free-brands-svg-icons';

function Footer(){
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    
      useEffect(() => {
        setCurrentYear(new Date().getFullYear());
      }, []);
  return (
    <footer className="bg-[#b0bdc1] p-8">
      <div className="flex flex-wrap justify-between items-start mb-16">
        {/* Logo and Address Section */}
        <div className='flex flex-col justify-center'>
                <h1 class=" text-lg font-bold text-black">Contact Us</h1>
                <div className='grid justify-center mt-4 gap-1 text-white'>
                  <div className='flex items-center gap-[11px] mb-1 text-black hover:text-gray-700'>
                    <FontAwesomeIcon icon={faLocationDot}/> <span> ENSI, Manouba</span>
                  </div>
                  <div className='flex items-center gap-2 mb-1 text-black hover:text-gray-700'>
                    <FontAwesomeIcon icon={faPhone}/> <span>+216 93 179 760</span>
                  </div>
                  <div className='flex items-center gap-2 mb-1 text-black hover:text-gray-700'>
                    <FontAwesomeIcon icon={faPaperPlane}/> <span>ieee.sb@ensi-uma.tn</span>
                  </div>
                  <div className='flex items-center gap-2 mb-1  text-black hover:text-gray-700'>
                    <a href='https://www.facebook.com/profile.php?id=61555870985449' className="" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faSquareFacebook} />    <span> facebook.com/ieee.ensi.sb</span>
                    </a>
                  </div >
                  
                </div>
              </div>

        {/* Navigation Links */}
        <div className="flex gap-16">
          {/* Main Links */}
          <div className='flex justify-center gap-3 md:text-[16px] text-[17px] md:mb-0 mb-5 about-title hidd'>
              <a href="#" className='text-black hover:text-[#428bca]'>Home</a>
              <Link to="" onClick={() => window.location.href='/GODS/AboutUs'} className='text-black hover:text-[#428bca]'>About</Link>
              <Link to="" onClick={() => window.location.href='/GODS/Registration'} className='text-black hover:text-[#428bca]'>Registration</Link>
              <Link to="" onClick={() => window.location.href='/Contact'} className='text-black hover:text-[#428bca]'>Contact</Link>
            </div>

         

          {/* Newsletter Section */}
         
        </div>
      </div>

      {/* Copyright */}
      <div className="flex justify-center items-center pt-3 -mb-2 border-t border-gray-400 flex-col-reverse md:flex-row">
            <p className="md:text-sm text-[13px] about-title hidd text-black">
              © Copyright {currentYear} IEEE ENSI SB. All rights reserved.
            </p>
          </div>
    </footer>
  );
};

export default Footer;
