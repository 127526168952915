import gods3 from './GODS.png'
import "./App.css";
import styles from "../../style";
const About = () => {
  return (
    <div className="App bg-[#edecec] relative top-[-10px] lg:top-0 mb-[-40px]">
      <h1 className="text-center text-[40px] font-semibold text-transparent  bg-clip-text bg-gradient-to-r from-purple-500 to-cyan-500 mb-2 pt-6">
          About the last edition !
        </h1>
       
      <div className="div_kbira2">
        
      
        <div className="div21 flex flex-col-reverse cont hidd">
          <div className="lg:pt-10">
            <p
              
              className={` ${styles.paragraph} text-justify text-justify-last lg:pl-[80px] pl-5`}
            >
              As the 'Go Data Science' event enters its highly anticipated third
              edition, we look back at the tremendous growth and success of its
              previous iterations. The event seamlessly combines Data Science
              and Artificial Intelligence, showcasing tremendous growth and
              success. Participants dive into immersive workshops, gaining
              insights into cutting-edge technologies. The highlight is the
              exhilarating Data Science Hackathon, where teams leverage AI and
              Data Science to solve real-world challenges.
            </p>
            <div className="flex flex-wrap items-center justify-center lg:gap-8">
              <div className="xtext-[#3a55b4] p-1 lg:p-4 py-10">
                <h1 className="mb-2 text-center text-xl font-bold tracking-tight text-black lg:text-center lg:text-4xl lg:font-extrabold lg:leading-none">
                  800+
                </h1>
                <span className="mt-1 font-semibold text-lg text-[#0061A8] uppercase">
                  Participants
                </span>
              </div>
              <div className="xtext-[#3a55b4] p-4 py-10">
                <h1 className="mb-2 text-center text-xl font-bold tracking-tight text-black lg:text-center lg:text-4xl lg:font-extrabold lg:leading-none">
                  6+
                </h1>
                <span className="mt-1 font-semibold uppercase text-lg text-[#0061A8]">
                  Workshops
                </span>
              </div>
              <div className="xtext-[#3a55b4] p-4 py-10">
                <h1 className="mb-2 text-center text-xl font-bold tracking-tight text-black lg:text-center lg:text-4xl lg:font-extrabold lg:leading-none">
                  3000 DT+
                </h1>
                <span className="mt-1 font-semibold uppercase text-lg text-[#0061A8] flex justify-center">
                  Prices
                </span>
              </div>
            </div>
          </div>
          <img src={gods3} alt="Image_GODS_2.0" id="img_gods20" className='pb-32' />
        </div>
      </div>
    </div>
  );
};

export default About;
