import React, { useState, useEffect } from "react";
import CountdownTimer from "../../Hero/Counter";
import time from './time1.png';

function Header() {
  const [registrationOpen, setRegistrationOpen] = useState(false);

  useEffect(() => {
    // Replace with your target date/time
    const targetDate = new Date("2025-01-26T20:00:00").getTime();

    const timer = setInterval(() => {
      const now = new Date().getTime();
      if (now >= targetDate) {
        setRegistrationOpen(true);
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="top-16 relative new-background new-text-color">
      <div className="bg-gray-900">
        <section className="grid grid-cols-12 items-center justify-center py-10 md:py-12">
          <div className="col-span-12 mx-auto">
            <h1 className="mb-8 text-center font-poppins font-semibold ss:text-[72px] text-[52px] text-white ss:leading-[100.8px] leading-[75px]">
              <span className="text-gradient font-bold">G</span>o{" "}
              <span className="text-gradient font-bold">D</span>ata{" "}
              <span className="text-gradient font-bold">S</span>cience{" "}
              <span className="text-gradient font-bold">4.0</span>
            </h1>

            <h1 className="mb-4 text-center text-xl font-bold tracking-tight lg:mb-10 lg:text-center lg:text-2xl lg:font-medium lg:leading-none text-[#B0BDC1]">
              Join the Data Science Fusion: <br /> <br /> competition,
              workshops, and prizes defining the forefront of innovation.
            </h1>
            <div className="mb-8 text-center lg:mb-7">
              <span className="flex justify-center">
                <img src={time} alt="" width={200} className="w-[300px]" />
              </span>
            </div>

            <div className="flex justify-center gap-5">
              {registrationOpen ? (
                <button
                  onClick={() => (window.location.href = "/GODS/Registration")}
                  className="px-6 py-3 rounded-md font-bold bg-[#FEDA5E] text-[#1D202B]"
                >
                  Register Now
                </button>
              ) : (
                <button
                  onClick={() => (window.location.href = "/GODS/ComingSoon")}
                  className="px-6 py-3 rounded-md font-bold bg-[#FEDA5E] text-[#1D202B]"
                >
                  Register Now
                </button>
              )}

              <a
                href="https://www.facebook.com/events/1058598075956465"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="px-6 py-3 rounded-md font-bold border-2 border-[#FEDA5E] text-[#FEDA5E] bg-transparent">
                  Learn More
                </button>
              </a>
            </div>

            <CountdownTimer />
          </div>
        </section>
      </div>
    </div>
  );
}

export default Header;
