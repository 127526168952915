import React, { useState, useEffect } from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import GODS from "../../../assets/Logos/gods4.0.png";

const Navbar = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isAfter8PM, setIsAfter8PM] = useState(false);

  // Toggle Menu Visibility
  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  // Check if the current time is after 8 PM on 26/01/2025
  useEffect(() => {
    const targetTime = new Date("2025-01-26T20:00:00"); // 8 PM on 26/01/2025

    const checkTime = () => {
      const now = new Date();
      if (now >= targetTime) {
        setIsAfter8PM(true);
      }
    };

    // Check time initially
    checkTime();

    // Optionally keep checking every second until the target time
    const interval = setInterval(checkTime, 1000);

    // Cleanup interval after the target time
    return () => clearInterval(interval);
  }, []);

  return (
    <header className="header fixed top-0 left-0 w-full h-[4.5rem] shadow-md bg-[#f2f2f2] z-10">
      <nav className="nav container md:mt-[-8px] mt-0">
        <div className="nav__data h-full flex justify-between items-center">
          <Link to="" onClick={() => (window.location.href = "/GODS")}>  
            <img src={GODS} alt="Logo" width={200} />
          </Link>
          <div
            className={`nav__toggle ${isMenuVisible ? "show-icon" : ""}`}
            id="nav-toggle"
            onClick={toggleMenu}
          >
            <i
              className={`ri-menu-line nav__toggle-menu opacity-1 z-50 ${
                isMenuVisible ? "hidden" : ""
              }`}
            ></i>
            <i
              className={`ri-close-line nav__toggle-close opacity-1 ${
                isMenuVisible ? "" : "hidden"
              }`}
            ></i>
          </div>
        </div>

        {/* NAV MENU */}
        <div
          className={`${
            isMenuVisible ? "nav__menu show-menu" : "nav__menu"
          }`}
          id="nav-menu"
        >
          <ul className="nav__list">
            <li>
              <Link
                to=""
                onClick={() => (window.location.href = "/GODS")}
                className="nav__link"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to=""
                onClick={() => (window.location.href = "/GODS/ComingSoon")}
                className="nav__link"
              >
                Program
              </Link>
            </li>
            <li>
              <Link
                to=""
                onClick={() => (window.location.href = "/GODS/AboutUs")}
                className="nav__link"
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to=""
                onClick={() => (window.location.href = "/GODS/ContactUs")}
                className="nav__link"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>

        {/* Registration Button */}
        <div>
          <ul className="nav__list hidden">
            <div className="Reg_buttom pointer">
              <li>
                <Link
                  to=""
                  onClick={() =>
                    (window.location.href = isAfter8PM
                      ? "/GODS/Registration"
                      : "/GODS/ComingSoon")
                  }
                  className="nav__link1"
                >
                  <span className="text-[#feda5e]">Registration</span>
                </Link>
              </li>
            </div>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
