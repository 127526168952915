import React, { useState } from 'react';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: ''
    });
    alert('Thank you for your message! We will get back to you soon.');
  };

  return (
    <div className="min-h-screen flex justify-center items-center bg-gray-900 ">
      <div className="sm:w-full w-80 max-w-lg text-center">
        <h1 className="text-yellow-400 text-3xl font-bold mb-4">Get In Touch With Us</h1>
        <p className="text-gray-400 mb-6 text-sm leading-relaxed">
          For more information about our product & services, please feel free to drop us an
          email. Our staff will always be there to help you out. Do not hesitate!
        </p>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="text-left">
            <label htmlFor="name" className="text-white block mb-1">Your Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Abc"
              required
              className="w-full p-3 rounded bg-white text-gray-900 focus:outline-none focus:ring-2 focus:ring-yellow-400"
            />
          </div>

          <div className="text-left">
            <label htmlFor="email" className="text-white block mb-1">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Abc@def.com"
              required
              className="w-full p-3 rounded bg-white text-gray-900 focus:outline-none focus:ring-2 focus:ring-yellow-400"
            />
          </div>

          <div className="text-left">
            <label htmlFor="subject" className="text-white block mb-1">Subject</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              placeholder="This is optional"
              className="w-full p-3 rounded bg-white text-gray-900 focus:outline-none focus:ring-2 focus:ring-yellow-400"
            />
          </div>

          <div className="text-left">
            <label htmlFor="message" className="text-white block mb-1">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Hi! I'd like to ask about"
              required
              className="w-full p-3 rounded bg-white text-gray-900 min-h-[100px] resize-none focus:outline-none focus:ring-2 focus:ring-yellow-400"
            />
          </div>

          <button
            type="submit"
            className="w-full bg-yellow-400 text-black font-bold py-3 rounded transition hover:bg-yellow-300 focus:outline-none focus:ring-2 focus:ring-yellow-500"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
