import React, { useEffect, useRef, useState } from 'react';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { fromLonLat } from 'ol/proj';
import 'ol/ol.css';

const SimpleMapComponent = () => {
  const mapRef = useRef();
  const mapInstance = useRef(null);
  const [error, setError] = useState(false);
  const ensiLocation = fromLonLat([10.063862455820939, 36.81390418079954]);

  useEffect(() => {
    try {
      if (mapInstance.current) {
        mapInstance.current.setTarget(undefined);
      }

      mapInstance.current = new Map({
        target: mapRef.current,
        layers: [
          new TileLayer({
            source: new OSM()
          })
        ],
        view: new View({
          center: ensiLocation,
          zoom: 16
        })
      });

    } catch (err) {
      console.error('Map initialization error:', err);
      setError(true);
    }

    return () => {
      if (mapInstance.current) {
        mapInstance.current.setTarget(undefined);
        mapInstance.current = null;
      }
    };
  }, []);

  if (error) {
    return <div style={{ color: '#ffd700', textAlign: 'center' }}>Error loading map</div>;
  }

  return (
    <div className="sm:w-full w-132 map-container bg-gray-900 -mb-32">
      <div className="map-wrapper -pb-16">
      <h1 className="text-yellow-400 text-3xl font-bold mb-4">Find Us ! </h1>
        <div 
          ref={mapRef} 
          className="map"
        />
      </div>

      <style>{`
        .map-container {
          min-height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .map-wrapper {
          width: 100%;
          max-width: 600px;
          text-align: center;
        }

        h1 {
          color: #ffd700;
          font-size: 2.5rem;
          margin-bottom: 1rem;
        }

        .map {
          width: 100%;
          height: 400px;
          border-radius: 5px;
          overflow: hidden;
          border: 2px solid #343a46;
        }

        @media (max-width: 640px) {
          .map-wrapper {
            padding: 10px;
          }

          h1 {
            font-size: 2rem;
          }
        }
      `}</style>
    </div>
  );
};

export default SimpleMapComponent;