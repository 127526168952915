import React from 'react'
import ContactForm from '../Home/Essidform/Essidform'
import SimpleMapComponent from '../Home/Essidform/SimpleMapComponent'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'

const Contactus = () => {
  return (
    <div>
        <Navbar/>
        <SimpleMapComponent/>
        <ContactForm/>
        <Footer/>
        
    </div>
  )
}

export default Contactus