import React from 'react'
import Navbar from '../Navbar/Navbar'
import Header from './Header/Header'
import BackToTopButton from '../../Home/Scroll'
import Footer from '../Footer/Footer'
import About from './About/About'
import Partners from './Partners/Partners'
import styles from '../style'
import CountdownTimer from '../Hero/Counter'
import { Popular } from '../News1/Popular'
import Projects from '../News1/Cards'
import ContactForm from './Essidform/Essidform'
import { NewsSection } from './News/News'
import SimpleMapComponent from './Essidform/SimpleMapComponent'
import Footer1 from '../Footer/Footer1'
import AboutGods3 from './About/Aboutgods3.0'

function Home() {

  return (
    <main>
        <section>
          <Navbar />
        </section>
        <section>
          
    {/* <div className={`bg-gradient-to-b from-[#1d202b] to-[#b0bdc8] w-full overflow-hidden ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>1d202b
        <Hero/>
        <CountdownTimer/>
      </div>
    </div> */}
     <Header/>
     <div className='bg-[#b0bdc1]'>
     <Popular/>
     </div>
        </section>
        <section>
        </section>
        <section>
          <AboutGods3/>
        </section>
        <section>
        <div className=" bg-gray-900 flex flex-col py-8">
      <SimpleMapComponent />
      <ContactForm />
        </div>
        </section>
        <BackToTopButton/>
        <section>
        <Partners/>
        </section>
        <section className='relative lg:top-0 top-[-15px]'>
          <Footer/>
        </section>
    </main>
    
  )
}

export default Home