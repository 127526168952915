import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './Popular.css';
import { lastedition, trainer, tv } from '../../../constantes';
import { testnews } from '../../../constantes';
import styles from '../style';

export const Popular = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 });

  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    }
  }, [controls, inView]);

  return (
    <>
      <div className="max-w-screen-xl mx-auto text-center">
        <h1 className="text-[40px] font-semibold text-transparent bg-clip-text bg-[#1d202b] mb-2 mt-16 pt-6">
          Latest News
        </h1>
        <p className="text-lg text-black">
          Don’t Miss a Beat! Stay in the Loop with Every Update
        </p>
      </div>
      <section 
  className="hero-section flex items-center justify-center w-4/5 min-h-screen px-6 py-12 mx-auto" 
  ref={ref}
>
        <motion.div
          className="cardPopular-grid"
          initial={{ opacity: 0, y: 50 }}
          animate={controls}
          transition={{ duration: 0.8 }}
        >
          {[
            { img: tv, category: '', heading: '' },
            { img: testnews, category: '', heading: '' },
            { img: lastedition, category: '', heading: '' },
          ].map((item, index) => (
            <motion.a
              key={index}
              className="object-cover cardPopular"
              href="#"
              whileHover={{ scale: 1.05 }}
            >
           <div
              className="cardPopular__background w-full h-full bg-contain bg-center bg-no-repeat"
              style={{ backgroundImage: `url(${item.img})` }}
            />
              <div className="cardPopular__content ">
                <p className="cardPopular__category">{item.category}</p>
                <h3 className={`cardPopular__heading ${styles.heading2}`}>{item.heading}</h3>
              </div>
            </motion.a>
          ))}
        </motion.div>
      </section>
      <div className="flex justify-center">
      <a
  href="https://www.facebook.com/events/1058598075956465"
  target="_blank"
  rel="noopener noreferrer"
  className="mb-8 px-6 py-3 text-lg text-[#feda5e] border-2 border-[#feda5e] rounded-md transition-all duration-300 hover:bg-[#feda5e] hover:text-[#1d202b] bg-[#1d202b]"
>
  See all →
</a>

</div>

    </>
  );
};
