import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareFacebook } from '@fortawesome/free-brands-svg-icons'



function CISTeam() {
  return (
    <div className="bg-[#00040f] md:h-screen md:pt-16 pt-12" id='Team'>
        <div className="px-6 py-10 mx-auto about-title hidd ">
          <h1 className="font-bold text-center text-[#ffffffb3] capitalize text-4xl tracking-widest">MEET OUR TEAM</h1>
          <h2 className="text-center text-gradient font-bold text-lg pt-2">A Quality And Motivated Board</h2>
          <div className="flex justify-center mx-auto">
                <span className="inline-block w-44 h-[2.5px] bg-[#4A8BD2] rounded-full opacity-50"></span>
                <span className="inline-block w-4 h-[2.5px] mx-1 bg-[#4A8BD2] rounded-full opacity-50"></span>
                <span className="inline-block w-2 h-[2.5px] bg-[#4A8BD2] rounded-full opacity-50"></span>
            </div>
        </div>
        <div className='cont hidd grid md:grid-cols-4 grid-cols-2 pb-10'>
          <div>
            <img src='https://res.cloudinary.com/dtungyafl/image/upload/v1727169706/Assets/Chapters/CIS/Oussema_uk8mch.png' alt="" />
            <div className='text-white pt-2'>
              <div className='flex justify-center'>
                <h1 className=' font-semibold md:text-base text-lg'>Oussama CHAABANE</h1>
              </div>
              <div className='flex justify-center'>
                <h2 className='text-gradient font-semibold text-sm'>CHAIR</h2>
              </div>
              <div className='flex justify-center gap-5 text-2xl md:pt-0 pt-1'>
                <a href="https://www.facebook.com/chouss5"><FontAwesomeIcon icon={faSquareFacebook} style={{color: "#ffffff",}} /></a>
                {/* <a href="https://www.instagram.com/sarah.boughriou/"><FontAwesomeIcon icon={faInstagram} style={{color: "#ffffff",}} /></a>
                <a href="https://www.linkedin.com/in/sarah-boughriou/"><FontAwesomeIcon icon={faLinkedin} style={{color: "#ffffff",}} /></a> */}
              </div>
            </div>
          </div>
          <div>
            <img src='https://res.cloudinary.com/dtungyafl/image/upload/v1727169706/Assets/Chapters/CIS/Nour_osvfv2.png' alt="" />
            <div className='text-white pt-2'>
              <div className='flex justify-center'>
                <h1 className=' font-semibold md:text-base text-lg'>Nour CHAKROUN</h1>
              </div>
              <div className='flex justify-center'>
                <h2 className='text-gradient font-semibold text-sm'>GENERAL SECRETARY & HR MANAGER</h2>
              </div>
              <div className='flex justify-center gap-5 text-2xl md:pt-0 pt-1'>
                <a href="https://www.facebook.com/nour.chakroun.94"><FontAwesomeIcon icon={faSquareFacebook} style={{color: "#ffffff",}} /></a>
                {/* <a href="https://www.instagram.com/yousrbouchaala/"><FontAwesomeIcon icon={faInstagram} style={{color: "#ffffff",}} /></a>
                <a href="https://www.linkedin.com/in/yousr-bouchaala-769245222/"><FontAwesomeIcon icon={faLinkedin} style={{color: "#ffffff",}} /></a> */}
              </div>
            </div>
          </div>
          <div>
            <img src='https://res.cloudinary.com/dtungyafl/image/upload/v1727169704/Assets/Chapters/CIS/cis_a2gqnu.png' alt="" />
            <div className='text-white pt-2'>
              <div className='flex justify-center'>
                <h1 className=' font-semibold md:text-base text-lg'>Ahmed BOUSSETTA</h1>
              </div>
              <div className='flex justify-center'>
                <h2 className='text-gradient font-semibold text-sm'>MEDIA MANAGER</h2>
              </div>
              <div className='flex justify-center gap-5 text-2xl md:pt-0 pt-1'>
                <a href="https://www.facebook.com/ahmed.boussetta.773"><FontAwesomeIcon icon={faSquareFacebook} style={{color: "#ffffff",}} /></a>
                {/* <a href="https://www.instagram.com/tasnim.mami.77/"><FontAwesomeIcon icon={faInstagram} style={{color: "#ffffff",}} /></a>
                <a href="https://www.linkedin.com/in/tasnim-mami-541730223/"><FontAwesomeIcon icon={faLinkedin} style={{color: "#ffffff",}} /></a> */}
              </div>
            </div>
          </div>
          <div>
            <img src='https://res.cloudinary.com/dtungyafl/image/upload/v1727169704/Assets/Chapters/CIS/Nadhem_wj2pgk.png' alt="" />
            <div className='text-white pt-2'>
              <div className='flex justify-center'>
                <h1 className=' font-semibold md:text-base text-lg'>Nadhem BENHADJALI</h1>
              </div>
              <div className='flex justify-center'>
                <h2 className='text-gradient font-semibold text-sm'>PROJECT MANAGER</h2>
              </div>
              <div className='flex justify-center gap-5 text-2xl md:pt-0 pt-1'>
                <a href="https://www.facebook.com/nadhem.belhadjali.1"><FontAwesomeIcon icon={faSquareFacebook} style={{color: "#ffffff",}} /></a>
                {/* <a href="https://www.instagram.com/islem_mansour/"><FontAwesomeIcon icon={faInstagram} style={{color: "#ffffff",}} /></a>
                <a href="https://www.linkedin.com/in/islem-mansour/"><FontAwesomeIcon icon={faLinkedin} style={{color: "#ffffff",}} /></a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default CISTeam