import React, { useEffect, useState } from "react";
import styles from "../style";

const CountdownTimer = () => {
  const calculateTimeLeft = () => {
    let year = new Date().getFullYear();
    const targetDate = new Date("2025-02-15T00:00:00");
    const difference = targetDate - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full">
     
      <div className=" flex flex-wrap justify-center items-center text-indigo-50 rounded-md ">
        {Object.keys(timeLeft).length ? (
          Object.entries(timeLeft).map(([interval, value]) => (
            <div key={interval} className="flex flex-col items-center text-center m-10 sm:m-10 p-2">
              <span className="text-4xl sm:text-6xl md:text-8xl font-bold font-poppins">{value}</span>
              <span className="text-lg  sm:text-2xl font-semibold">{interval}</span>
            </div>
          ))
        ) : (
          <span className="text-2xl font-semibold">Time's up!</span>
        )}
      </div>
    </div>
  );
};

export default CountdownTimer;
