//Import
import IAS from "../assets/Logos/ias.png";
import WIE from "../assets/Logos/wie.png";
import CIS from "../assets/Logos/cis.png";
import CS from "../assets/Logos/cs.png";
import Startech from "../assets/images/Startech.jpg";
import Pinkit from "../assets/images/Pinkit.jpg";
import TSYP11 from "../assets/images/TSYP11.jpg";
import Wie_Event from "../assets/images/Wie_event.jpg";
import Logo from "../assets/Logos/Logo.png";
import Xtreme from "../assets/images/Xtreme.jpg";
import IEEE from "../assets/Logos/IEEE.png";
import { CloudinaryImage } from "@cloudinary/url-gen";
import { scale } from "@cloudinary/url-gen/actions/resize";
import { quality, format } from "@cloudinary/url-gen/actions/delivery";
// Navbar
import trainer from '../assets/Trainer.jpg'
import testnews from '../assets/newsgods/bootcamp.jpg'
import tv from '../assets/newsgods/tv.jpg'
import lastedition from '../assets/newsgods/lastedition.jpg'
// Navbar
export { IAS, CIS, CS, WIE, Startech, Pinkit, TSYP11, IEEE,trainer,testnews,tv, lastedition}

const getCloudinaryImageUrl = (publicId) => {
  const myImage = new CloudinaryImage(publicId, { cloudName: "dtungyafl" });
  return myImage
    .resize(scale().width(1000)) // Resizing the image to 1000px width
    .delivery(quality("auto")) // Automatically adjust the quality
    .delivery(format("auto")) // Automatically select the best format (e.g., WebP)
    .toURL(); // Returns the URL for the transformed image
};
// Executive Board
export const TeamMembers1 = [
  {
    name: "Aya BOUSSAIDI",
    role: "CHAIRWOMAN",
    backImg: getCloudinaryImageUrl("Assets/Bureau/Copy_of_10_fswnv1"),
    FbLink: "https://www.facebook.com/profile.php?id=100008751094945",
    InstaLink: "https://www.instagram.com/aya_boussaid/",
    LinkedInLink: "https://www.linkedin.com/in/aya-boussaid-3508812a0/",
  },
  {
    name: "Aymen MASMOUDI",
    role: "VICE-CHAIR",
    backImg: getCloudinaryImageUrl("Assets/Bureau/Copy_of_2_btunyq"),
    FbLink: "https://www.facebook.com/profile.php?id=100009859565355",
    InstaLink: "",
    LinkedInLink: "https://www.linkedin.com/in/aymen-masmoudi-18175a2a1/",
  },

  {
    name: "Oumayma HAMMAMI",
    role: "GENERAL SECRETARY",
    backImg: getCloudinaryImageUrl("Assets/Bureau/20_zhnkdh.jpg"),
    FbLink: "https://www.facebook.com/oumayma.hammami.9026",
    InstaLink: "https://www.instagram.com/_oumaima__hammami_/",
    LinkedInLink: "https://www.linkedin.com/in/oumayma-hammami111/",
  },
  {
    name: "Khalil KRIFI",
    role: "TREASURER",
    backImg: getCloudinaryImageUrl("Assets/Bureau/4_ysbiy0.jpg"),
    FbLink: "https://www.facebook.com/profile.php?id=100006779831357",
    InstaLink: "https://www.instagram.com/khalil_krifi_/",
    LinkedInLink:
      "https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Amember%3A664654166&keywords=khalil%20krifi&origin=RICH_QUERY_TYPEAHEAD_HISTORY&position=0&sid=WFr&spellCorrectionEnabled=false",
  },
];
export const TeamMembers2 = [
  {
    name: "Aziz SOUID",
    role: "HR MANAGER",
    backImg: getCloudinaryImageUrl("Assets/Bureau/3_csb4a3.jpg"),
    FbLink: "https://www.facebook.com/azizsouidess",
    InstaLink: "https://www.instagram.com/aziz_souid/",
    LinkedInLink: "https://www.linkedin.com/in/mohamedazizsouid/",
  },
  {
    name: "Yasmine RIABI",
    role: "SPONSORING MANAGER",
    backImg: getCloudinaryImageUrl("Assets/Bureau/Copy_of_9_rmy6fo.jpg"),
    FbLink: "https://www.facebook.com/profile.php?id=100009342008019",
    InstaLink: "https://www.instagram.com/yasmine_riabi/",
    LinkedInLink: "https://www.linkedin.com/in/yasmine-riabi-43b4292a0/",
  },
  {
    name: "Imen BOUSSETTA",
    role: "MEDIA MANAGER",
    backImg: getCloudinaryImageUrl("Assets/Bureau/15_zavlin.jpg"),
    FbLink: "https://www.facebook.com/imen.boussetta1",
    InstaLink: "https://www.instagram.com/imen.boussetta/",
    LinkedInLink: "https://www.linkedin.com/in/imen-boussetta-33aa48283/",
  },
  {
    name: "Riadh BELGACEM",
    role: "PROJECT MANAGER",
    backImg: getCloudinaryImageUrl("Assets/Bureau/Copy_of_4_vbtc9w.jpg"),
    FbLink: "https://www.facebook.com/Riadhbelgacem02",
    InstaLink: "https://www.instagram.com/riyadhbelgacem/",
    LinkedInLink: "https://www.linkedin.com/in/riadh-belgacem-712b821b0/",
  },
];

// Testimonials cards
export const cardData = [
  {
    name: "SEBAI Ghaissen",
    post: "IEEE ENSI CHAIR 2023",
    imageSrc: getCloudinaryImageUrl("Assets/Testimonials/Ghaissen_bl3zli.jpg"),
    delay: -1,
    para: "Some people are born into wonderful families. Others have to find or create them. Being a member of a family is a priceless membership that we pay nothing for but love.",
  },
  {
    name: "BOUAZIZ Saif",
    post: "IEEE ENSI CHAIR 2022",
    imageSrc: getCloudinaryImageUrl("Assets/Testimonials/Saif_x4rkmt.jpg"),
    delay: 0,
    para: "The way of Success is the way of continous pursuit of knowledge.",
  },
  {
    name: "SAIDI Chiheb",
    post: "IEEE ENSI CHAIR 2021",
    imageSrc: getCloudinaryImageUrl("Assets/Testimonials/Chiheb_oaexqt.jpg"),
    delay: 1,
    para: "There are no secrets to Success. It is the result of Preparation, Hard Work, and Learning from failure.",
  },
  {
    name: "NAIMI Aziz",
    post: "IEEE ENSI CHAIR 2019",
    imageSrc: getCloudinaryImageUrl("Assets/Testimonials/Aziz_hg9tzv.jpg"),
    delay: 2,
    para: "Every journey starts with a single step, you must focus on your transferable experiences for your career.",
  },
  {
    name: "BEN YAHIA Ilyes",
    post: "IEEE ENSI VICE CHAIR 2019",
    imageSrc: getCloudinaryImageUrl("Assets/Testimonials/Ilyes_vtled5.jpg"),
    delay: 2,
    para: "It's during the worst storms of your life that you will get to see the true colors of the people who say they care about IEEE, and in IEEE ENSI, everyone cares !",
  }
];

//News
export { Xtreme, Logo, Wie_Event };

// Events

export const slides = [
  {
    url: Xtreme,
    title: "IEEEXtreme 17.0",
    Text: "Seeking a thrilling coding competition that's unlike any other? 🤓 Look no further than IEEEXtreme, where we've blended the excitement of competition with the art of coding 🫵🏼. In this 17th edition, brace yourself for a journey filled with enigmatic challenges that will keep you on your toes 🔥. Get ready to dive into a captivating coding quest 🧡 that promises to be an unforgettable experience!",
    condition: false,
    location: "ENSI, Manouba",
    date: "October 28th",
  },
  {
    url: Pinkit,
    title: "Pink it 5.0",
    Text: "🌸 Join us in the fight against breast cancer! 🎗️💕 Our mission is to raise awareness, foster dialogue, and take action. 🗣️ 💪 We're here to empower women to take control of their health. Don't wait! Schedule a consultation and get screened. 🩺 Early detection saves lives! 💕🌟",
    condition: false,
    location: "ENSI, Manouba",
    date: "October 25th",
  },
];
