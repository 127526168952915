import React from 'react'
import './index.css'
import Slider from 'react-infinite-logo-slider'
import CISTS from './CISTS.png'
import Thinkit from '../../../../assets/Partners/Thinkit.png'
import Zerda from './zerda-tech-logo.svg'
import CIT from './conformit_logo.png.webp'
import YP from './IEEEYoungProfessionalsLogoTM_RGB_Stacked (1).png'
import IEEE from './IEEEFOUNDATION.png'
function Partners() {
  return (
    <div className='pb-10 bg-[#edecec] top-[-15px] lg:top-0 relative' >
        <div className='lg:mx-10'>
            <div className=' mb-12 text-red-800 flex justify-center'>
            <h1 className="text-center text-[40px] font-semibold text-transparent  bg-clip-text bg-gradient-to-r from-purple-500 to-cyan-500 mb-2 pt-6">
          Meet our sponsors
        </h1>
            </div>
            <div className='Slide relative lg:mx-[-30px]'>
                <Slider
                    width="250px"
                    duration={30}
                    pauseOnHover={false}
                    blurBorders={false}
                    blurBoderColor={'#0C0D26'}
                >
                    <Slider.Slide>
                        <img src={CISTS} alt="CIS" className='w-36 cursor-pointer lg:ml-10' />
                    </Slider.Slide>
                    <Slider.Slide>
                        <img src={YP} alt="THINKIT" className='w-44 cursor-pointer' />
                    </Slider.Slide>
                    <Slider.Slide>
                        <img src={IEEE} alt="ZERDA" className='w-48 cursor-pointer' />
                    </Slider.Slide>
                   
                    <Slider.Slide>
                        <img src={CISTS} alt="CIS" className='w-36 cursor-pointer lg:ml-10' />
                    </Slider.Slide>
                    <Slider.Slide>
                        <img src={YP} alt="THINKIT" className='w-44 cursor-pointer' />
                    </Slider.Slide>
                    <Slider.Slide>
                        <img src={IEEE} alt="ZERDA" className='w-48 cursor-pointer' />
                    </Slider.Slide>
                    
                </Slider>
            </div>
            
        </div>
    </div>
  )
}

export default Partners